<template>
  <div
    class="cols spaceMain"
    :style="{
      width: '500px',
    }"
  >
    <div>
      <div
        :style="{
          backgroundColor: '#56567E',
          fontSize: '20px',
          fontWeight: 'bold',
          textAlign: 'left',
          padding: '5px',
        }"
      >
        장소 : {{ mainData.placeName }}
      </div>
      <div
        class="spaceIcons"
        :style="{
          border: '1px solid #ffffff',
          display: 'flex',
          marginTop: '12px',
          borderRadius: '8px',
        }"
        v-if="mainData.gsConnStatus !== null"
      >
        <div>
          <div v-if="mainData.gsConnStatus !== null">
            GS
            <img :src="gsIcon[mainData.gsConnStatus]" alt="icon" />
          </div>
        </div>
        <div>
          <div v-if="mainData.msConnStatus !== null">
            MS
            <img :src="msIcon[mainData.msConnStatus]" alt="icon" />
          </div>
        </div>
        <div>
          <div v-if="mainData.scConnStatus !== null">
            SC
            <img :src="scIcon[mainData.scConnStatus]" alt="icon" />
          </div>
        </div>
        <div></div>
        <div>
          <div v-if="mainData.msBattLevel !== null">
            MS
            <img :src="msBatt[mainData.msBattLevel]" alt="icon" />
          </div>
        </div>
        <div>
          <div v-if="mainData.scBattLevel !== null">
            SC
            <img :src="scBatt[mainData.scBattLevel]" alt="icon" />
          </div>
        </div>
      </div>
      <div
        v-else
        class="spaceIcons"
        :style="{
          border: '1px solid #ffffff',
          display: 'block',
          marginTop: '12px',
          borderRadius: '8px',
          fontSize: '25px',
          padding: '20px',
          textAlign: 'center',
        }"
      >
        등록된 장비가 존재하지 않습니다.
      </div>
      <b-col
        class="MainSpace"
        :style="{
          border: '1px solid #ffffff',
          marginTop: '5px',
          borderRadius: '8px',
        }"
      >
        <div>
          <div
            :style="{
              margin: '30px',
              fontSize: '21px',
              borderRadius: '20px',
              backgroundColor: '#373654',
              padding: '10px 15px 10px 15px',
              textAlign: 'left',
            }"
          >
            <span :style="{ verticalAlign: 'middle' }">{{
              mainData.spaceName
            }}</span>
          </div>
          <div :style="{ display: 'flex', marginBottom: '15px' }">
            <div
              :style="{
                width: '70%',
                textAlign: 'left',
                paddingLeft: '20px',
                paddingRight: '30px',
                alignSelf: 'end',
              }"
            >
              <p :style="{ paddingLeft: '20px', fontSize: '20px' }">
                현재 온도
              </p>
              <p
                :style="{
                  fontSize: '100px',
                  lineHeight: '0.6',
                  paddingLeft: '20px',
                  marginBottom: '25px',
                }"
              >
                {{ mainData.envTemp }}˚
              </p>
              <div class="progressCheck">
                <b-col cols="4" class="label">
                  <span :style="{ verticalAlign: 'middle' }">습도</span>
                </b-col>
                <b-col cols="8" class="label">
                  <b-progress
                    :value="mainData.envHumid"
                    :max="100"
                  ></b-progress>
                </b-col>
              </div>
              <div class="progressCheck">
                <b-col cols="4" class="label">
                  <span :style="{ verticalAlign: 'middle' }">미세</span>
                </b-col>
                <b-col cols="8" class="label">
                  <b-progress :value="mainData.envPm10" :max="500"></b-progress>
                </b-col>
              </div>
              <div class="progressCheck">
                <b-col cols="4" class="label">
                  <span :style="{ verticalAlign: 'middle' }">초미세</span>
                </b-col>
                <b-col cols="8" class="label">
                  <b-progress :value="mainData.envPm25" :max="500"></b-progress>
                </b-col>
              </div>
              <div class="progressCheck">
                <b-col cols="4" class="label">
                  <span :style="{ verticalAlign: 'middle' }">CO2</span>
                </b-col>
                <b-col cols="8" class="label">
                  <b-progress :value="mainData.envCo2" :max="9999"></b-progress>
                </b-col>
              </div>
              <div class="progressCheck">
                <b-col cols="4" class="label">
                  <span :style="{ verticalAlign: 'middle' }">TVOC</span>
                </b-col>
                <b-col cols="8" class="label">
                  <b-progress
                    :value="mainData.envTvoc"
                    :max="9999"
                  ></b-progress>
                </b-col>
              </div>
            </div>
            <div :style="{ width: '30%', textAlign: 'right' }">
              <div :style="{ position: 'relative' }">
                <b-img
                  class="gsImage"
                  :src="
                    mainData.envPm10 >= 0 && mainData.envPm10 < 30
                      ? gsStatusImg[0]
                      : mainData.envPm10 >= 30 && mainData.envPm10 < 80
                      ? gsStatusImg[1]
                      : mainData.envPm10 >= 80 && mainData.envPm10 < 150
                      ? gsStatusImg[2]
                      : mainData.envPm10 >= 150 && mainData.envPm10 < 500
                      ? gsStatusImg[3]
                      : gsStatusImg[3]
                  "
                  alt=""
                  :style="{ width: '100%' }"
                >
                </b-img>
                <div
                  :style="{
                    position: 'absolute',
                    top: '-8px',
                    left: '-12px',
                  }"
                >
                  <vue-ellipse-progress
                    :legend="false"
                    :progress="(mainData.envPm10 / 500) * 100"
                    half
                    :dot="
                      mainData.envPm10 >= 0 && mainData.envPm10 < 30
                        ? '20 #1CB0FF'
                        : mainData.envPm10 >= 30 && mainData.envPm10 < 80
                        ? '20 #68C995'
                        : mainData.envPm10 >= 80 && mainData.envPm10 < 150
                        ? '20 #FFB24A'
                        : mainData.envPm10 >= 150 && mainData.envPm10 < 500
                        ? '20 #FB6A57'
                        : '20 #FB6A57'
                    "
                    emptyColor="#171629"
                    :color="
                      mainData.envPm10 >= 0 && mainData.envPm10 < 30
                        ? '#1CB0FF'
                        : mainData.envPm10 >= 30 && mainData.envPm10 < 80
                        ? '#68C995'
                        : mainData.envPm10 >= 80 && mainData.envPm10 < 150
                        ? '#FFB24A'
                        : mainData.envPm10 >= 150 && mainData.envPm10 < 500
                        ? '#FB6A57'
                        : '#FB6A57'
                    "
                    :size="310"
                    thickness="5"
                    emptyThickness="5"
                    reverse
                    angle="90"
                  />
                </div>
              </div>
            </div>
          </div>
          <div ref="spaceFunction" class="spaceFunction">
            <span @click="buttonActive(0)">
              <img class="disable" :src="disableFunctionIcon[0]" alt="" />
              <img class="disable" :src="activeFunctionIcon[0]" alt="" />
              수동
            </span>
            <span @click="buttonActive(1)">
              <img class="disable" :src="disableFunctionIcon[1]" alt="" />
              <img class="disable" :src="activeFunctionIcon[1]" alt="" />
              온도유지
            </span>
            <span @click="buttonActive(2)">
              <img class="disable" :src="disableFunctionIcon[2]" alt="" />
              <img class="disable" :src="activeFunctionIcon[2]" alt="" />
              AI
            </span>
            <span @click="isScheduleButton">
              <img class="disable" :src="disableFunctionIcon[3]" alt="" />
              <img class="disable" :src="activeFunctionIcon[3]" alt="" />
              스케쥴
            </span>
          </div>
        </div>
      </b-col>
      <div
        class="bottomFunction"
        v-if="mainData.ctrlOnOff !== null"
        :style="{ borderRadius: '8px' }"
      >
        <div class="bottomwrap">
          <div
            :style="{
              backgroundColor: mainData.ctrlOnOff === 0 ? '#A0A2C3' : '#1CB0FF',
              width: '60px',
              height: '60px',
              borderRadius: '50%',
            }"
          >
            <img
              :src="airSetImage[mainData.ctrlOnOff]"
              alt=""
              :style="{ width: '50px', height: '50px' }"
            />
          </div>
        </div>
        <div class="bottomwrap">
          <div>
            {{ mainData.ctrlTempName }}
          </div>
          <div
            :style="{
              fontSize: '30px',
              color: mainData.ctrlOnOff === 0 ? '#A0A2C3' : '#1CB0FF',
            }"
          >
            {{ mainData.ctrlTemp }}
          </div>
        </div>
        <div class="bottomwrap">
          <div
            :style="{
              marginBottom: '10px',
            }"
          >
            모드
          </div>
          <div
            :style="{
              border: '1px solid #ffffff',
              borderRadius: '15px',
              color: mainData.ctrlOnOff === 0 ? '#A0A2C3' : '#1CB0FF',
            }"
          >
            {{
              mainData.ctrlMode === 1
                ? "냉방"
                : mainData.ctrlMode === 2
                ? "난방"
                : mainData.ctrlMode === 3
                ? "송풍"
                : mainData.ctrlMode === 4
                ? "제습"
                : "??"
            }}
          </div>
        </div>
        <div class="bottomwrap">
          <div :style="{ marginBottom: '10px' }">풍량</div>
          <div
            :style="{
              border: '1px solid #ffffff',
              borderRadius: '15px',
              color: mainData.ctrlOnOff === 0 ? '#A0A2C3' : '#1CB0FF',
            }"
          >
            {{
              mainData.ctrlFan === 1
                ? "약"
                : mainData.ctrlFan === 2
                ? "중"
                : mainData.ctrlFan === 3
                ? "강"
                : mainData.ctrlFan === 4
                ? "자동"
                : "??"
            }}
          </div>
        </div>
      </div>
      <div
        v-else
        class="bottomFunction"
        :style="{
          borderRadius: '8px',
          fontSize: '25px',
          padding: '20px',
          textAlign: 'center',
        }"
      >
        에어컨 제어정보가 존재하지 않습니다.
      </div>
    </div>
  </div>
</template>

<script>
import { VueEllipseProgress } from "vue-ellipse-progress";

export default {
  name: "spaceMain",
  props: ["mainData"],
  components: {
    VueEllipseProgress,
  },
  data() {
    return {
      isActive: [true, false, false],
      isSchedule: false,
      gsProgressColor: ["#1CB0FF", "#68C995", "#FFB24A", "#FB6A57"],

      disableFunctionIcon: [
        require("../assets/icon/ico_mode_ai_off.png"),
        require("../assets/icon/ico_mode_remote_off.png"),
        require("../assets/icon/ico_mode_schedule_off.png"),
        require("../assets/icon/ico_mode_temp_off.png"),
      ],
      activeFunctionIcon: [
        require("../assets/icon/ico_mode_ai.png"),
        require("../assets/icon/ico_mode_remote.png"),
        require("../assets/icon/ico_mode_schedule.png"),
        require("../assets/icon/ico_mode_temp.png"),
      ],
      gsStatusImg: [
        require("../assets/icon/img_my_gs_01.png"),
        require("../assets/icon/img_my_gs_02.png"),
        require("../assets/icon/img_my_gs_03.png"),
        require("../assets/icon/img_my_gs_04.png"),
      ],
      airSetImage: [
        require("../assets/icon/ico_ctrl_mode_off.png"),
        require("../assets/icon/ico_ctrl_mode.png"),
      ],
      gsIcon: [
        require("../assets/icon/ico_device_menu.png"),
        require("../assets/icon/ico_device_gs.png"),
      ],
      msIcon: [
        require("../assets/icon/ico_device_menu.png"),
        require("../assets/icon/ico_device_ms.png"),
      ],
      scIcon: [
        require("../assets/icon/ico_device_menu.png"),
        require("../assets/icon/ico_device_sc.png"),
      ],
      msBatt: [
        require("../assets/icon/ico_batteryon_0.png"),
        require("../assets/icon/ico_batteryon_0.png"),
        require("../assets/icon/ico_batteryon_1.png"),
        require("../assets/icon/ico_batteryon_2.png"),
        require("../assets/icon/ico_batteryon_3.png"),
        require("../assets/icon/ico_batteryon_3.png"),
        require("../assets/icon/ico_batteryon_3.png"),
        require("../assets/icon/ico_batteryon_3.png"),
        require("../assets/icon/ico_batteryon_3.png"),
      ],

      scBatt: [
        require("../assets/icon/ico_batteryon_0.png"),
        require("../assets/icon/ico_batteryon_0.png"),
        require("../assets/icon/ico_batteryon_1.png"),
        require("../assets/icon/ico_batteryon_2.png"),
        require("../assets/icon/ico_batteryon_3.png"),
        require("../assets/icon/ico_batteryon_3.png"),
        require("../assets/icon/ico_batteryon_3.png"),
        require("../assets/icon/ico_batteryon_3.png"),
        require("../assets/icon/ico_batteryon_3.png"),
      ],
    };
  },
  mounted() {
    this.buttonActive(this.mainData.ctrlOperationMode);
    this.isScheduleButton(this.mainData.schActive);
  },
  methods: {
    buttonActive(idx) {
      // let button = document.getElementsByClassName("spaceFunction")[0];

      let button = this.$refs.spaceFunction;

      if (button.children[idx] !== undefined) {
        for (let i = 0; i < this.isActive.length; i++) {
          this.isActive[i] = false;
          button.children[i].style.border = "1px solid #a0a2c3";
          button.children[i].style.color = "#a0a2c3";
          button.children[i].children[0].className = "active";
          button.children[i].children[1].className = "disable";
        }

        this.isActive[idx] = true;
        button.children[idx].style.border = "1px solid #1CB0FF";
        button.children[idx].style.color = "#1CB0FF";
        button.children[idx].children[0].className = "disable";
        button.children[idx].children[1].className = "active";
      } else {
        for (let i = 0; i < this.isActive.length; i++) {
          this.isActive[i] = false;
          button.children[i].style.border = "1px solid #a0a2c3";
          button.children[i].style.color = "#a0a2c3";
          button.children[i].children[0].className = "active";
          button.children[i].children[1].className = "disable";
        }
      }
    },
    isScheduleButton(idxFirst) {
      if (idxFirst <= 0) {
        this.isSchedule = false;
      } else if (idxFirst > 1) {
        this.isSchedule = true;
      } else {
        this.isSchedule = !this.isSchedule;
      }

      let button = this.$refs.spaceFunction;

      if (this.isSchedule === true) {
        button.children[3].style.border = "1px solid #1CB0FF";
        button.children[3].style.color = "#1CB0FF";
        button.children[3].children[0].className = "disable";
        button.children[3].children[1].className = "active";
      } else {
        button.children[3].style.border = "1px solid #a0a2c3";
        button.children[3].style.color = "#a0a2c3";
        button.children[3].children[0].className = "active";
        button.children[3].children[1].className = "disable";
      }
    },
  },
};
</script>

<style></style>
