import axios from "axios";
import store from '../store/index';
// axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

let UserState = store.state.userState;
// console.log(UserState);

let apiUrl = process.env.VUE_APP_API_SERVER
// let apiUrl = process.env.VUE_APP_TEST_SERVER

let instance = axios.create({
  // baseURL: "http://localhost:8082",
  // baseURL: "/",
  //운영
  // baseURL: "https://api.mecs.thekie.com",
  //개발
  //baseURL: "http://192.168.10.11:8082",

  baseURL: apiUrl,
  headers: {
    "Content-type": "application/json",
    "Authentication": `Bearer ${UserState.accessToken}`
  },
  // responseType: 'blob'
  timeout: 15000
})


instance.interceptors.request.use(
  (config) => {
    // console.log('isLoading');
    // store.state.isLoading = true;

    let accessToken = store.state.userState.accessToken;

    config.headers.Authorization = `Bearer ${accessToken}`

    return config;
  },
  (error) => {
    console.log(error);
    // console.log('Loading End');
    // store.state.isLoading = false;

    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    // console.log('Loading End');
    // store.state.isLoading = false;

    return response;
  },
  async (error) => {
    console.log(error.response);
    // console.log('Loading End');
    // store.state.isLoading = false;


    if (error.response.status === 401) {
      let refreshToken = store.state.userState.refreshToken;
      let result = await instance.post(`/v2/signin/update-token?token=${refreshToken}`);

      store.commit("setUserState", {
        accessToken: result.data.accessToken,
        refreshToken: result.data.refreshToken,
        userId: result.data.userId,
      });
    }

    return Promise.reject(error);
  }
);

export default instance;
