<template>
  <div class="SpaceChart">
    <div class="spaceInTitle">
      {{ title + " : " + value }}
    </div>
    <div :style="{ marginTop: '10px' }">
      <svg
        viewBox="0 0 7 7"
        xmlns="http://www.w3.org/2000/svg"
        class="svgCircle"
      >
        <circle cx="3" cy="3" r="3" fill="#1CB0FF" />
      </svg>
      <span>좋음</span>
      <svg
        viewBox="0 0 7 7"
        xmlns="http://www.w3.org/2000/svg"
        class="svgCircle"
      >
        <circle cx="3" cy="3" r="3" fill="#68C995" />
      </svg>
      <span>보통</span>
      <svg
        viewBox="0 0 7 7"
        xmlns="http://www.w3.org/2000/svg"
        class="svgCircle"
      >
        <circle cx="3" cy="3" r="3" fill="#FFB24A" />
      </svg>
      <span>나쁨</span>

      <svg
        viewBox="0 0 7 7"
        xmlns="http://www.w3.org/2000/svg"
        class="svgCircle"
      >
        <circle cx="3" cy="3" r="3" fill="#FB6A57" />
      </svg>
      <span>매우나쁨</span>
    </div>
    <div class="spaceInChart">
      <LineChart
        ref="canvas"
        :chartData="setChartData"
        :options="options"
      ></LineChart>
    </div>
  </div>
</template>

<script>
import LineChart from "../chart/LineChart.vue";

import GradientCondition from "../chart/GradientCondition.js";

export default {
  name: "SpaceChart",
  props: ["title", "value", "data"],
  components: {
    LineChart,
  },
  watch: {
    data() {
      this.setCompile();
    },
  },
  created() {},
  mounted() {
    // console.log('newMount')
    // this.$nextTick(() => {
      this.setCompile();
    // });
  },
  data() {
    return {
      isloaded: true,
      setChartData: {},
      gradient: null,
      checkGradient: null,
      gradientCondition: new GradientCondition(),
      options: {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1,
        title: {
          display: false,
          position: "left",
          fontSize: 20,
          fontColor: "#f1f1f1",
          fontStyle: "bold",
          text: "테스트",
        },
        legend: {
          display: false,
          postion: "top",
          align: "start",
          labels: {
            fontSize: 13,
            fontFamily: "Nanum Gothic",
            fontColor: "#f1f1f1",
            fontStyle: "normal",
            strokeStyle: "000000",
          },
        },
        scales: {
          xAxes: [
            {
              ticks: {
                fontColor: "#f1f1f1",
                fontSize: 10,
                fontStyle: "bold",
                autoSkip: true,
                maxTicksLimit: 2,
                maxRotation: 0,
                minRotation: 0,
                labelOffset: 25,
              },
              gridLines: {
                color: "#7d7d7d",
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                fontColor: "#f1f1f1",
                fontSize: 10,
                fontStyle: "bold",
              },
              gridLines: {
                color: "#7d7d7d",
              },
            },
          ],
        },
        gridLines: {
          color: "#7d7d7d",
        },
      },
    };
  },
  methods: {
    setCompile() {
      this.isloaded = true;
      // console.log(canvas);

      let label = [];
      let datas = [];

      for (let i = 0; i < this.data.length; i++) {
        label.push(this.data[i].baseData);
        datas.push(this.data[i].value);
      }

      let canvas = this.$refs.canvas.$refs.canvas;


      if (canvas !== undefined) {
        let gradient = canvas
          .getContext("2d")
          .createLinearGradient(0, 0, 0, 450);
        gradient.addColorStop(0, "rgba(28, 176, 255, 0.5)");
        gradient.addColorStop(0.5, "rgba(28, 176, 255, 0.1)");
        gradient.addColorStop(1, "rgba(28, 176, 255, 0)");

        let checkGradient = canvas
          .getContext("2d")
          .createLinearGradient(20, 0, 330, 0);

        for (let i = 0; i < datas.length; i++) {
          let start;

          start = (i / (datas.length - 1)).toFixed(2);

          let value = datas[i];
          let title = this.title;

          let result;

          switch (title) {
            case "온도": {
              result = this.gradientCondition.tempCheckValues(
                [10, 17, 20, 25, 28, 33, 50],
                ["W", "N", "G", "N", "W", "B"],
                value
              );
              break;
            }
            case "미세먼지": {
              result = this.gradientCondition.tempCheckValues(
                [0, 30, 80, 150, 500],
                ["G", "N", "W", "B"],
                value
              );

              break;
            }
            case "습도": {
              result = this.gradientCondition.tempCheckValues(
                [20, 30, 40, 60, 70, 80, 100],
                ["W", "N", "G", "N", "W", "B"],
                value
              );

              break;
            }
            case "초미세먼지": {
              result = this.gradientCondition.tempCheckValues(
                [0, 15, 35, 75, 500],
                ["G", "N", "W", "B"],
                value
              );

              break;
            }
            case "CO2": {
              result = this.gradientCondition.tempCheckValues(
                [0, 500, 700, 1000, 9999],
                ["G", "N", "W", "B"],
                value
              );

              break;
            }
            case "TVOC": {
              result = this.gradientCondition.tempCheckValues(
                [0, 220, 660, 2200, 9999],
                ["G", "N", "W", "B"],
                value
              );

              break;
            }
          }
          this.gradientCondition[`gradi${result}`](start, checkGradient);
        }

        let backGradient = gradient;
        let boerderGradient = checkGradient;

        this.setChartData = {
          labels: label,
          datasets: [
            {
              label: this.title,
              backgroundColor: backGradient,
              borderColor: boerderGradient,
              fill: "start",
              data: datas,
            },
          ],
        };

      }
    },
  },
};
</script>

<style lang="scss">
.SpaceChart {
  .spaceInTitle {
    background-color: #56567e;
    font-size: 20px;
    font-weight: bold;
    text-align: left;
    padding: 5px;
  }
  .svgCircle {
    width: 20px;
  }
  span {
    margin-right: 10px;
    margin-left: 1px;
    margin-bottom: 3px;
    display: inline-block;
    vertical-align: middle;
  }
  .spaceInChart {
    padding: 20px;
    height: 290px;
    canvas {
      height: 260px !important;
    }
  }
}
</style>