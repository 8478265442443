var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cols spaceMain",style:({
    width: '500px',
  })},[_c('div',[_c('div',{style:({
        backgroundColor: '#56567E',
        fontSize: '20px',
        fontWeight: 'bold',
        textAlign: 'left',
        padding: '5px',
      })},[_vm._v(" 장소 : "+_vm._s(_vm.mainData.placeName)+" ")]),(_vm.mainData.gsConnStatus !== null)?_c('div',{staticClass:"spaceIcons",style:({
        border: '1px solid #ffffff',
        display: 'flex',
        marginTop: '12px',
        borderRadius: '8px',
      })},[_c('div',[(_vm.mainData.gsConnStatus !== null)?_c('div',[_vm._v(" GS "),_c('img',{attrs:{"src":_vm.gsIcon[_vm.mainData.gsConnStatus],"alt":"icon"}})]):_vm._e()]),_c('div',[(_vm.mainData.msConnStatus !== null)?_c('div',[_vm._v(" MS "),_c('img',{attrs:{"src":_vm.msIcon[_vm.mainData.msConnStatus],"alt":"icon"}})]):_vm._e()]),_c('div',[(_vm.mainData.scConnStatus !== null)?_c('div',[_vm._v(" SC "),_c('img',{attrs:{"src":_vm.scIcon[_vm.mainData.scConnStatus],"alt":"icon"}})]):_vm._e()]),_c('div'),_c('div',[(_vm.mainData.msBattLevel !== null)?_c('div',[_vm._v(" MS "),_c('img',{attrs:{"src":_vm.msBatt[_vm.mainData.msBattLevel],"alt":"icon"}})]):_vm._e()]),_c('div',[(_vm.mainData.scBattLevel !== null)?_c('div',[_vm._v(" SC "),_c('img',{attrs:{"src":_vm.scBatt[_vm.mainData.scBattLevel],"alt":"icon"}})]):_vm._e()])]):_c('div',{staticClass:"spaceIcons",style:({
        border: '1px solid #ffffff',
        display: 'block',
        marginTop: '12px',
        borderRadius: '8px',
        fontSize: '25px',
        padding: '20px',
        textAlign: 'center',
      })},[_vm._v(" 등록된 장비가 존재하지 않습니다. ")]),_c('b-col',{staticClass:"MainSpace",style:({
        border: '1px solid #ffffff',
        marginTop: '5px',
        borderRadius: '8px',
      })},[_c('div',[_c('div',{style:({
            margin: '30px',
            fontSize: '21px',
            borderRadius: '20px',
            backgroundColor: '#373654',
            padding: '10px 15px 10px 15px',
            textAlign: 'left',
          })},[_c('span',{style:({ verticalAlign: 'middle' })},[_vm._v(_vm._s(_vm.mainData.spaceName))])]),_c('div',{style:({ display: 'flex', marginBottom: '15px' })},[_c('div',{style:({
              width: '70%',
              textAlign: 'left',
              paddingLeft: '20px',
              paddingRight: '30px',
              alignSelf: 'end',
            })},[_c('p',{style:({ paddingLeft: '20px', fontSize: '20px' })},[_vm._v(" 현재 온도 ")]),_c('p',{style:({
                fontSize: '100px',
                lineHeight: '0.6',
                paddingLeft: '20px',
                marginBottom: '25px',
              })},[_vm._v(" "+_vm._s(_vm.mainData.envTemp)+"˚ ")]),_c('div',{staticClass:"progressCheck"},[_c('b-col',{staticClass:"label",attrs:{"cols":"4"}},[_c('span',{style:({ verticalAlign: 'middle' })},[_vm._v("습도")])]),_c('b-col',{staticClass:"label",attrs:{"cols":"8"}},[_c('b-progress',{attrs:{"value":_vm.mainData.envHumid,"max":100}})],1)],1),_c('div',{staticClass:"progressCheck"},[_c('b-col',{staticClass:"label",attrs:{"cols":"4"}},[_c('span',{style:({ verticalAlign: 'middle' })},[_vm._v("미세")])]),_c('b-col',{staticClass:"label",attrs:{"cols":"8"}},[_c('b-progress',{attrs:{"value":_vm.mainData.envPm10,"max":500}})],1)],1),_c('div',{staticClass:"progressCheck"},[_c('b-col',{staticClass:"label",attrs:{"cols":"4"}},[_c('span',{style:({ verticalAlign: 'middle' })},[_vm._v("초미세")])]),_c('b-col',{staticClass:"label",attrs:{"cols":"8"}},[_c('b-progress',{attrs:{"value":_vm.mainData.envPm25,"max":500}})],1)],1),_c('div',{staticClass:"progressCheck"},[_c('b-col',{staticClass:"label",attrs:{"cols":"4"}},[_c('span',{style:({ verticalAlign: 'middle' })},[_vm._v("CO2")])]),_c('b-col',{staticClass:"label",attrs:{"cols":"8"}},[_c('b-progress',{attrs:{"value":_vm.mainData.envCo2,"max":9999}})],1)],1),_c('div',{staticClass:"progressCheck"},[_c('b-col',{staticClass:"label",attrs:{"cols":"4"}},[_c('span',{style:({ verticalAlign: 'middle' })},[_vm._v("TVOC")])]),_c('b-col',{staticClass:"label",attrs:{"cols":"8"}},[_c('b-progress',{attrs:{"value":_vm.mainData.envTvoc,"max":9999}})],1)],1)]),_c('div',{style:({ width: '30%', textAlign: 'right' })},[_c('div',{style:({ position: 'relative' })},[_c('b-img',{staticClass:"gsImage",style:({ width: '100%' }),attrs:{"src":_vm.mainData.envPm10 >= 0 && _vm.mainData.envPm10 < 30
                    ? _vm.gsStatusImg[0]
                    : _vm.mainData.envPm10 >= 30 && _vm.mainData.envPm10 < 80
                    ? _vm.gsStatusImg[1]
                    : _vm.mainData.envPm10 >= 80 && _vm.mainData.envPm10 < 150
                    ? _vm.gsStatusImg[2]
                    : _vm.mainData.envPm10 >= 150 && _vm.mainData.envPm10 < 500
                    ? _vm.gsStatusImg[3]
                    : _vm.gsStatusImg[3],"alt":""}}),_c('div',{style:({
                  position: 'absolute',
                  top: '-8px',
                  left: '-12px',
                })},[_c('vue-ellipse-progress',{attrs:{"legend":false,"progress":(_vm.mainData.envPm10 / 500) * 100,"half":"","dot":_vm.mainData.envPm10 >= 0 && _vm.mainData.envPm10 < 30
                      ? '20 #1CB0FF'
                      : _vm.mainData.envPm10 >= 30 && _vm.mainData.envPm10 < 80
                      ? '20 #68C995'
                      : _vm.mainData.envPm10 >= 80 && _vm.mainData.envPm10 < 150
                      ? '20 #FFB24A'
                      : _vm.mainData.envPm10 >= 150 && _vm.mainData.envPm10 < 500
                      ? '20 #FB6A57'
                      : '20 #FB6A57',"emptyColor":"#171629","color":_vm.mainData.envPm10 >= 0 && _vm.mainData.envPm10 < 30
                      ? '#1CB0FF'
                      : _vm.mainData.envPm10 >= 30 && _vm.mainData.envPm10 < 80
                      ? '#68C995'
                      : _vm.mainData.envPm10 >= 80 && _vm.mainData.envPm10 < 150
                      ? '#FFB24A'
                      : _vm.mainData.envPm10 >= 150 && _vm.mainData.envPm10 < 500
                      ? '#FB6A57'
                      : '#FB6A57',"size":310,"thickness":"5","emptyThickness":"5","reverse":"","angle":"90"}})],1)],1)])]),_c('div',{ref:"spaceFunction",staticClass:"spaceFunction"},[_c('span',{on:{"click":function($event){return _vm.buttonActive(0)}}},[_c('img',{staticClass:"disable",attrs:{"src":_vm.disableFunctionIcon[0],"alt":""}}),_c('img',{staticClass:"disable",attrs:{"src":_vm.activeFunctionIcon[0],"alt":""}}),_vm._v(" 수동 ")]),_c('span',{on:{"click":function($event){return _vm.buttonActive(1)}}},[_c('img',{staticClass:"disable",attrs:{"src":_vm.disableFunctionIcon[1],"alt":""}}),_c('img',{staticClass:"disable",attrs:{"src":_vm.activeFunctionIcon[1],"alt":""}}),_vm._v(" 온도유지 ")]),_c('span',{on:{"click":function($event){return _vm.buttonActive(2)}}},[_c('img',{staticClass:"disable",attrs:{"src":_vm.disableFunctionIcon[2],"alt":""}}),_c('img',{staticClass:"disable",attrs:{"src":_vm.activeFunctionIcon[2],"alt":""}}),_vm._v(" AI ")]),_c('span',{on:{"click":_vm.isScheduleButton}},[_c('img',{staticClass:"disable",attrs:{"src":_vm.disableFunctionIcon[3],"alt":""}}),_c('img',{staticClass:"disable",attrs:{"src":_vm.activeFunctionIcon[3],"alt":""}}),_vm._v(" 스케쥴 ")])])])]),(_vm.mainData.ctrlOnOff !== null)?_c('div',{staticClass:"bottomFunction",style:({ borderRadius: '8px' })},[_c('div',{staticClass:"bottomwrap"},[_c('div',{style:({
            backgroundColor: _vm.mainData.ctrlOnOff === 0 ? '#A0A2C3' : '#1CB0FF',
            width: '60px',
            height: '60px',
            borderRadius: '50%',
          })},[_c('img',{style:({ width: '50px', height: '50px' }),attrs:{"src":_vm.airSetImage[_vm.mainData.ctrlOnOff],"alt":""}})])]),_c('div',{staticClass:"bottomwrap"},[_c('div',[_vm._v(" "+_vm._s(_vm.mainData.ctrlTempName)+" ")]),_c('div',{style:({
            fontSize: '30px',
            color: _vm.mainData.ctrlOnOff === 0 ? '#A0A2C3' : '#1CB0FF',
          })},[_vm._v(" "+_vm._s(_vm.mainData.ctrlTemp)+" ")])]),_c('div',{staticClass:"bottomwrap"},[_c('div',{style:({
            marginBottom: '10px',
          })},[_vm._v(" 모드 ")]),_c('div',{style:({
            border: '1px solid #ffffff',
            borderRadius: '15px',
            color: _vm.mainData.ctrlOnOff === 0 ? '#A0A2C3' : '#1CB0FF',
          })},[_vm._v(" "+_vm._s(_vm.mainData.ctrlMode === 1 ? "냉방" : _vm.mainData.ctrlMode === 2 ? "난방" : _vm.mainData.ctrlMode === 3 ? "송풍" : _vm.mainData.ctrlMode === 4 ? "제습" : "??")+" ")])]),_c('div',{staticClass:"bottomwrap"},[_c('div',{style:({ marginBottom: '10px' })},[_vm._v("풍량")]),_c('div',{style:({
            border: '1px solid #ffffff',
            borderRadius: '15px',
            color: _vm.mainData.ctrlOnOff === 0 ? '#A0A2C3' : '#1CB0FF',
          })},[_vm._v(" "+_vm._s(_vm.mainData.ctrlFan === 1 ? "약" : _vm.mainData.ctrlFan === 2 ? "중" : _vm.mainData.ctrlFan === 3 ? "강" : _vm.mainData.ctrlFan === 4 ? "자동" : "??")+" ")])])]):_c('div',{staticClass:"bottomFunction",style:({
        borderRadius: '8px',
        fontSize: '25px',
        padding: '20px',
        textAlign: 'center',
      })},[_vm._v(" 에어컨 제어정보가 존재하지 않습니다. ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }