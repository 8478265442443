<template>
  <div class="SpaceComponents">
    <b-container fluid class="bv-example-row bv-example-row-flex-cols">
      <b-row
        align-v="stretch"
        :style="{ alignContent: 'center', justifyContent: 'center' }"
      >
        <!-- <div :style="{ width: '30%' }">
          <SpaceMain :mainData="setMainData" />
        </div>
        <div :style="{ width: '70%' }">
          <b-row
            align-v="stretch"
            :style="{ alignContent: 'center', justifyContent: 'center' }"
          >
            <b-col class="cols" lg="4" md="12" :style="{ padding: 0 }">
              <b-col>
                <SpaceChart
                  :title="'온도'"
                  :value="mainData.envTemp + '도'"
                  :data="setTrandData[5]"
                />
              </b-col>
              <b-col>
                <SpaceChart
                  :title="'미세먼지'"
                  :value="mainData.envPm10 + '㎍/m³'"
                  :data="setTrandData[3]"
                />
              </b-col>
            </b-col>
            <b-col class="cols" lg="4" md="12" :style="{ padding: 0 }">
              <b-col>
                <SpaceChart
                  :title="'습도'"
                  :value="mainData.envHumid + '%'"
                  :data="setTrandData[1]"
                />
              </b-col>
              <b-col>
                <SpaceChart
                  :title="'초미세먼지'"
                  :value="mainData.envPm25 + '㎍/m³'"
                  :data="setTrandData[4]"
                />
              </b-col>
            </b-col>
            <b-col class="cols" lg="4" md="12" :style="{ padding: 0 }">
              <b-col>
                <SpaceChart
                  :title="'CO2'"
                  :value="mainData.envCo2 + 'ppm'"
                  :data="setTrandData[0]"
                />
              </b-col>
              <b-col>
                <SpaceChart
                  :title="'TVOC'"
                  :value="mainData.envTvoc +'ppb'"
                  :data="setTrandData[6]"
                />
              </b-col>
            </b-col>
          </b-row>
        </div> -->

        <b-col class="mainCol" lg="4" md="12" :style="{textAlign: '-webkit-center', minWidth: '500px'}">
          <SpaceMain :mainData="setMainData" />
        </b-col>
        <b-col class="graphCol" lg="8" md="12" >
          <b-row
            align-v="stretch"
            :style="{ alignContent: 'center', justifyContent: 'center' }"
          >
            <b-col class="cols" lg="4" md="12" :style="{ padding: 0 }">
              <b-col>
                <SpaceChart
                  :title="'온도'"
                  :value="mainData.envTemp + '˚'"
                  :data="setTrandData[5]"
                />
              </b-col>
              <b-col>
                <SpaceChart
                  :title="'미세먼지'"
                  :value="mainData.envPm10 + '㎍/m³'"
                  :data="setTrandData[3]"
                />
              </b-col>
            </b-col>
            <b-col class="cols" lg="4" md="12" :style="{ padding: 0 }">
              <b-col>
                <SpaceChart
                  :title="'습도'"
                  :value="mainData.envHumid + '%'"
                  :data="setTrandData[1]"
                />
              </b-col>
              <b-col>
                <SpaceChart
                  :title="'초미세먼지'"
                  :value="mainData.envPm25 + '㎍/m³'"
                  :data="setTrandData[4]"
                />
              </b-col>
            </b-col>
            <b-col class="cols" lg="4" md="12" :style="{ padding: 0 }">
              <b-col>
                <SpaceChart
                  :title="'CO2'"
                  :value="mainData.envCo2 + 'ppm'"
                  :data="setTrandData[0]"
                />
              </b-col>
              <b-col>
                <SpaceChart
                  :title="'TVOC'"
                  :value="mainData.envTvoc + 'ppb'"
                  :data="setTrandData[6]"
                />
              </b-col>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import SpaceMain from "./spaceMain.vue";
import SpaceChart from "./spaceChart.vue";

export default {
  name: "SpaceComponents",
  props: ["mainData"],
  components: {
    SpaceChart,
    SpaceMain,
  },
  data() {
    return {
      circleSize: 280,
    };
  },
  computed: {
    setMainData() {
      let mainData = {
        ctrlFan: this.mainData.ctrlFan,
        ctrlMode: this.mainData.ctrlMode,
        ctrlOnOff: this.mainData.ctrlOnOff,
        ctrlOperationMode: this.mainData.ctrlOperationMode,
        ctrlTemp: this.mainData.ctrlTemp,
        ctrlTempName: this.mainData.ctrlTempName,
        envCo2: this.mainData.envCo2,
        envHumid: this.mainData.envHumid,
        envPm10: this.mainData.envPm10,
        envPm25: this.mainData.envPm25,
        envTemp: this.mainData.envTemp,
        envTvoc: this.mainData.envTvoc,
        gsConnStatus: this.mainData.gsConnStatus,
        msBattLevel: this.mainData.msBattLevel,
        msConnStatus: this.mainData.msConnStatus,
        placeId: this.mainData.placeId,
        placeName: this.mainData.placeName,
        scBattLevel: this.mainData.scBattLevel,
        scConnStatus: this.mainData.scConnStatus,
        spaceId: this.mainData.spaceId,
        spaceName: this.mainData.spaceName,
      };

      return mainData;
    },
    setTrandData() {
      let trendsDataList = [];
      let baseDateList = [];

      for (let i = 0; i < this.mainData.trends.length; i++) {
        baseDateList.push(this.mainData.trends[i].baseDate);
      }

      let co2Data = [];
      let humidData = [];
      let pm01Data = [];
      let pm10Data = [];
      let pm25Data = [];
      let tempData = [];
      let vocData = [];

      for (let i = this.mainData.trends.length - 1; i > 0; i--) {
        for (let j = baseDateList.length - 1; j > 0; j--) {
          if (this.mainData.trends[i].baseDate === baseDateList[j]) {
            co2Data.push({
              baseData: baseDateList[j],
              value: this.mainData.trends[i].co2,
            });
            humidData.push({
              baseData: baseDateList[j],
              value: this.mainData.trends[i].humid,
            });
            pm01Data.push({
              baseData: baseDateList[j],
              value: this.mainData.trends[i].pm01,
            });
            pm10Data.push({
              baseData: baseDateList[j],
              value: this.mainData.trends[i].pm10,
            });
            pm25Data.push({
              baseData: baseDateList[j],
              value: this.mainData.trends[i].pm25,
            });
            tempData.push({
              baseData: baseDateList[j],
              value: this.mainData.trends[i].temp,
            });
            vocData.push({
              baseData: baseDateList[j],
              value: this.mainData.trends[i].voc,
            });
            break;
          }
        }

        if (co2Data.length === 24) {
          break;
        }
      }
      co2Data.reverse();
      humidData.reverse();
      pm01Data.reverse();
      pm10Data.reverse();
      pm25Data.reverse();
      tempData.reverse();
      vocData.reverse();

      trendsDataList.push(co2Data);
      trendsDataList.push(humidData);
      trendsDataList.push(pm01Data);
      trendsDataList.push(pm10Data);
      trendsDataList.push(pm25Data);
      trendsDataList.push(tempData);
      trendsDataList.push(vocData);

      return trendsDataList;
    },
  },
};
</script>

<style lang="scss">
.SpaceComponents {
  height: 100%;
  .container-fluid {
    height: 100%;
    .row {
      height: 100%;
      .mainCol{
        @media screen and (min-width: 992px) {
          width: 29% !important;
          flex: 0 0 29.3333333% !important;
        }
      }
      .graphCol{
        @media screen and (min-width: 992px) {
          width: 70% !important;
          flex: 0 0 70.6666667% !important;
        }

       
      }

      .cols {
        border: 5px solid #201f3b;
        background-color: #282743;
        color: #ffffff;
        .spaceIcons {
          div {
            width: 100%;
            padding: 5px;
            img {
              width: 100%;
            }
          }
        }
        .MainSpace {
          .progressCheck {
            margin-bottom: 6px;
            display: flex;
            font-size: 15px;
            align-items: center;
            .label {
              text-align: right;
              padding-right: 5px;
            }
            .progress {
              background-color: #171629;
              .progress-bar {
                background-color: #a0a2c3;
                margin: 1px;
                border-radius: 5px;
              }
            }
          }
        }
        .spaceFunction {
          padding: "5px";
          margin-top: 3px;
          margin-bottom: 20px;
          span {
            cursor: pointer;

            padding: 5px;
            margin-left: 5px;
            margin-right: 5px;
            border-radius: 25px;
            font-size: 17px;
            border: 1px solid #a0a2c3;
            color: #a0a2c3;
            .disable {
              display: none;
            }
            .active {
              display: inline-block;
            }
            img {
              width: 22px;
              margin-bottom: 4px;
            }
          }
        }
        .bottomFunction {
          margin-top: 5px;
          border: 1px solid #ffffff;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .bottomwrap {
            width: 100%;
            height: 60px;
            margin: 10px;
            text-align: center;
            text-align: -webkit-center;
          }
        }
      }
    }
  }
}
</style>