<template>
  <div id="app">
    <Loading
      :active.sync="isLoading"
      :is-full-page="fullPage"
    ></Loading>

    <router-view />
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import {mapGetters} from "vuex";

export default {
  name: "App",
  components: {
    Loading,
  },
  data() {
    return {
      fullPage: true,
    };
  },

  computed: {
    ...mapGetters({
      isLoading: "getLoading"
    })
  }
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
