<template>
  <!-- <Carousel
    :per-page="1"
    :mouse-drag="true"
    :center-mode="true"
    :loop="true"
    :autoplay="false"
    :autoplayTimeout="1000"
  >
    <Slide
      v-for="(data, i) in allData"
      :key="`key${i}`"
      :style="{ alignContent: 'center', alignItems: 'center' }"
    >
      <div class="SpaceView row align-items-center">
        <SpaceComponents :data="data"></SpaceComponents>
      </div>
    </Slide>
  </Carousel> -->
  <div :style="{ height: '100%' }">
    <TopbarComponents></TopbarComponents>
    <span
      v-if="dataError !== ''"
      :style="{ fontSize: '25px', color: '#ffffff' }"
      >현재 데이터 통신에 오류가 발생 중입니다.</span
    >
    <div
      v-show="spaceSelect === '전체'"
      :style="{ height: 'calc(100% - 65px)' }"
    >
      <swiper ref="mySwiper" :options="swiperOptions">
        <swiper-slide
          v-for="(mainData, i) in allData"
          :key="`key${i}`"
          :style="{ alignContent: 'center', alignItems: 'center' }"
        >
          <div class="SpaceView row align-items-center">
            <SpaceComponents :mainData="mainData"></SpaceComponents>
          </div>
        </swiper-slide>
        <!-- <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div> -->
      </swiper>
      <div class="swiper-pagination" slot="pagination"></div>
    </div>

    <b-row
      v-if="spaceSelect !== '전체'"
      :style="{ width: '100%', margin: 'auto', height: 'calc(100% - 105px)' }"
    >
      <SpaceComponents :mainData="checkSingleData()"></SpaceComponents>
    </b-row>

    <!-- <FooterComponents></FooterComponents> -->
  </div>
</template>

<script>
import axios from "axios";
import API from "../api/API.js";

import { mapGetters } from "vuex";

import SpaceComponents from "../components/spaceComponents.vue";
import TopbarComponents from "../components/Topbar/TopbarComponents.vue";
// import FooterComponents from "../components/Footer/FooterComponents.vue";

import { Swiper, SwiperSlide, Autoplay } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

Swiper.use([Autoplay]);

export default {
  name: "SpaceView",
  components: {
    SpaceComponents,
    TopbarComponents,
    // FooterComponents,
    Swiper,
    SwiperSlide,
  },

  data() {
    return {
      isLoading: false,
      fullPage: true,
      count: 1,
      dataError: "",
      singleRender: null,
      swiperOptions: {
        autoHeight: true,
        // longSwipes: false,
        // longSwipesMs: 1000,
        // navigation: {
        //   nextEl: ".swiper-button-next",
        //   prevEl: ".swiper-button-prev",
        // },
        // loop: true,
        // loopAdditionalSlides: 3,
        // loopFillGroupWithBlank: true,
        // slidesPerView: 1,
        // spaceBetween: 16,
        // centeredSlides: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          type: "bullets",
        },
        autoplay: {
          delay: 10000,
          disableOnInteraction: false,
        },
        // effect: "coverflow",
        // coverflowEffect: {
        //   rotate: 30,
        //   slideShadows: false,
        // },
        // Some Swiper option/callback...
      },
    };
  },
  computed: {
    ...mapGetters({
      allData: "getDataList",
      autoPlayState: "getAutoPlayState",
      spaceSelect: "getSpaceSelect",
      userState: "getUserState",
      userPlace: "getUserPlace",
      userSpace: "getUserSpace",
      userSpaceEnv: "getuserSpaceEnv",
      userTrend: "getuserTrend",
      userDevices: "getuserDevices",
      userDevciesDetail: "getuserDevciesDetail",
    }),
  },

  watch: {
    autoPlayState() {
      // console.log("change autoplaystate");
      this.autoPlayMethod();
    },
  },
  created() {
    // console.log(this.allData, this.spaceSelect, this.userState);
    if (this.userState.userId === "000") {
      this.getAdminData();
    } else {
      this.getDatas();
    }
    // this.getData();
  },
  mounted() {
    let nowTime = new Date();

    let laterTime = new Date(nowTime.getFullYear(), nowTime.getMonth(), nowTime.getDate());
    laterTime.setDate(nowTime.getDate() + 1);
    let refreshTime = laterTime.getTime() - nowTime.getTime();
        
    setTimeout(() => {
      location.reload();
    }, refreshTime);

    // console.log("is render");
    // console.log("Current Swiper instance object", this.$refs.mySwiper);
    // console.log(this.$refs.mySwiper);
    // this.mySwiper.slideTo(3, 1000, false);
  },
  updated() {
    // console.log("render chagne");
    // this.$store.commit('setLoading', false);
  },
  methods: {
    checkSingleData() {
      // console.log(this.allData);
      for (let i = 0; i < this.allData.length; i++) {
        // console.log(this.spaceSelect, this.allData[i].spaceName);
        if (this.allData[i].spaceName === this.spaceSelect) {
          return this.allData[i];
        }
      }
    },
    autoPlayMethod() {
      // console.log("call autoplaymethod");
      if (this.autoPlayState === true) {
        this.$refs.mySwiper.$swiper.autoplay.start();
      } else {
        this.$refs.mySwiper.$swiper.autoplay.stop();
      }
    },
    getInfo: function () {
      this.$refs.tinySlider.slider.getInfo();
    },
    async getDatas() {
      //데이터 시작 로그
      // console.log("--- start get data ---");

      // 공간 정보

      let place = await API.getPlace();

      this.$store.commit("setUserPlace", place.data);

      // 장소 정보
      let spaceResult = [];

      for (let i = 0; i < this.userPlace.length; i++) {
        let space = await API.getSpace(this.userPlace[i].placeId);

        for (let j = 0; j < space.data.length; j++) {
          spaceResult.push(space.data[j]);
        }
      }

      this.$store.commit("setUserSpace", spaceResult);

      // 장소 환경 정보
      let spaceEnv = [];

      for (let i = 0; i < this.userSpace.length; i++) {
        spaceEnv.push(
          API.getSpaceEnvir(
            this.userSpace[i].placeId,
            this.userSpace[i].spaceId
          )
        );
      }

      let spaceEnvResult = await axios.all(spaceEnv);

      let setEnvData = [];

      for (let i = 0; i < spaceEnvResult.length; i++) {
        setEnvData.push(spaceEnvResult[i].data);
      }

      this.$store.commit("setuserSpaceEnv", setEnvData);

      // 디바이스
      let devices = [];

      for (let i = 0; i < this.userSpace.length; i++) {
        devices.push(
          API.getDevices(this.userSpace[i].placeId, this.userSpace[i].spaceId)
        );
      }

      let deviceResult = await axios.all(devices);

      let setDeviceData = [];
      for (let i = 0; i < deviceResult.length; i++) {
        setDeviceData.push(deviceResult[i].data);
      }

      this.$store.commit("setuserDevices", setDeviceData);

      // 디바이스 상세
      let devicesDetail = [];

      for (let i = 0; i < this.userDevices.length; i++) {
        for (let j = 0; j < this.userDevices[i].length; j++) {
          devicesDetail.push(
            API.getDevicesDetail(
              this.userDevices[i][j].placeId,
              this.userDevices[i][j].spaceId,
              this.userDevices[i][j].devId
            )
          );
        }
      }

      let deviceDetailResult = await axios.all(devices);

      let setDeviceDetailData = [];
      for (let i = 0; i < deviceDetailResult.length; i++) {
        setDeviceDetailData.push(deviceDetailResult[i].data);
      }

      this.$store.commit("setuserDevciesDetail", setDeviceData);

      // 트렌드 정보
      let trends = [];

      for (let i = 0; i < this.userSpace.length; i++) {
        trends.push(
          API.getTrend(this.userSpace[i].placeId, this.userSpace[i].spaceId)
        );
      }
      let trendResult = await axios.all(trends);

      let setTrendData = [];
      for (let i = 0; i < trendResult.length; i++) {
        setTrendData.push(trendResult[i].data);
      }

      this.$store.commit("setuserTrend", setTrendData);

      this.setDataForm();

      setTimeout(this.getDatas, 30000);
    },
    setDataForm() {
      let envform = [];
      // let trendform = [];
      for (let i = 0; i < this.userSpaceEnv.length; i++) {
        let opertaionMode = null;
        if (this.userSpaceEnv[i].operationModesStatus !== null) {
          if (this.userSpaceEnv[i].operationModesStatus.manualMode === true) {
            opertaionMode = 0;
          } else if (
            this.userSpaceEnv[i].operationModesStatus.holdingTempNode === true
          ) {
            opertaionMode = 1;
          } else if (
            this.userSpaceEnv[i].operationModesStatus.aiMode === true
          ) {
            opertaionMode = 2;
          }
        }

        let dataFrom = {
          spaceId: this.userSpaceEnv[i].spaceId,
          spaceName: this.userSpaceEnv[i].spaceName,
          ctrlFan:
            this.userSpaceEnv[i].airControl !== null
              ? this.userSpaceEnv[i].airControl.fanSpeed
              : null,
          ctrlMode:
            this.userSpaceEnv[i].airControl !== null
              ? this.userSpaceEnv[i].airControl.mode
              : null,
          ctrlOnOff:
            this.userSpaceEnv[i].airControl !== null
              ? this.userSpaceEnv[i].airControl.onOff
              : null,
          ctrlOperationMode: opertaionMode,
          ctrlTemp:
            this.userSpaceEnv[i].airControl !== null
              ? (this.userSpaceEnv[i].airControl.temperature / 10).toFixed(0)
              : null,
          ctrlTempName:
            this.userSpaceEnv[i].airControl !== null
              ? this.userSpaceEnv[i].airControl.holdingTemp === 0
                ? "희망온도"
                : this.userSpaceEnv[i].airControl.holdingTemp === 1
                ? "유지온도"
                : this.userSpaceEnv[i].airControl.holdingTemp === 2
                ? "쾌적온도"
                : "??"
              : "??",
          envCo2:
            this.userSpaceEnv[i].sensor !== null
              ? this.userSpaceEnv[i].sensor.co2
              : null,
          envHumid:
            this.userSpaceEnv[i].sensor !== null
              ? this.userSpaceEnv[i].sensor.humid / 10
              : null,
          envPm10:
            this.userSpaceEnv[i].sensor !== null
              ? this.userSpaceEnv[i].sensor.pm10
              : null,
          envPm25:
            this.userSpaceEnv[i].sensor !== null
              ? this.userSpaceEnv[i].sensor.pm25
              : null,
          envTemp:
            this.userSpaceEnv[i].sensor !== null
              ? this.userSpaceEnv[i].sensor.temp / 10
              : null,
          envTvoc:
            this.userSpaceEnv[i].sensor !== null
              ? this.userSpaceEnv[i].sensor.tvoc
              : null,
          placeId: this.userSpaceEnv[i].placeId,
          placeName: this.userSpaceEnv[i].placeName,
          schActive: this.userSpaceEnv[i].onoffSchedule === true ? 1 : 0,
          gsConnStatus: null,
          msBattLevel: null,
          msConnStatus: null,
          scBattLevel: null,
          scConnStatus: null,
        };

        for (let j = 0; j < this.userDevciesDetail[i].length; j++) {
          if (this.userDevciesDetail[i][j].devType === "GS") {
            dataFrom.gsConnStatus = this.userDevciesDetail[i][j].devConnStatus;
          } else if (this.userDevciesDetail[i][j].devType === "MS") {
            dataFrom.msConnStatus = this.userDevciesDetail[i][j].devConnStatus;
            dataFrom.msBattLevel = this.userDevciesDetail[i][j].battLevel;
          } else if (this.userDevciesDetail[i][j].devType === "SC") {
            dataFrom.scConnStatus = this.userDevciesDetail[i][j].devConnStatus;
            dataFrom.scBattLevel = this.userDevciesDetail[i][j].battLevel;
          }
        }

        dataFrom.trends = this.userTrend[i];

        envform.push(dataFrom);
      }

      // 데이터 리스트 폼
      // console.log("--- 데이터 리스트 폼 ---");
      // console.log(envform);

      this.$store.commit("setDataList", envform);
    },
    async getAdminData() {
      let result;
      try {
        // console.log("getdata load");
        this.dataError = "";
        result = await API.getAdminData();
        result.data.sort();

        let filterData = result.data;

        for (let i = 0; i < filterData.length; i++) {
          if (filterData[i].spaceName.includes("테스트")) {
            filterData.splice(i, 1);
            i--;
            continue;
          } else if (filterData[i].trends.length === 0) {
            filterData.splice(i, 1);
            i--;
            continue;
          }
        }

        this.$store.dispatch("checkData", filterData);
      } catch (e) {
        this.dataError = "Error";
      }
      // for (let i = 0; i < this.count; i++) {
      //   result.data[0].trends.push({
      //     baseDate: "2022-03-22 13",
      //     co2: 573.7,
      //     humid: 11.8,
      //     pm01: 23.7,
      //     pm10: 25.3,
      //     pm25: 24.6,
      //     temp: 30.3,
      //     voc: 7.9,
      //   });
      // }
      // this.count++;
      // console.log(this.count, result);
      setTimeout(this.getAdminData, 30000);
    },
    onResize() {
      this.nextTick(() => {
        this.$refs["mySwiper"].$swiper.emit("resize");
      });
    },
  },
};
</script>

<style lang="scss">
.SpaceView {
  background-color: #201f3b;
  width: 100%;
  overflow-x: hidden;
  margin: 0% !important;

  // transform: translateY(25%);
}
.swiper-pagination {
  width: 100%;
  height: 40px;
  .swiper-pagination-bullet {
    width: 15px;
    height: 15px;
    margin: 5px;
    background: #ffffff;
  }
}
</style>
