

import http from './AxiosConfig.js';

class API {
  login(email, password) {
    let headers = {
      appId: "KIE.MECS.Web.User",
    };

    let params = {
      email: email,
      password: password
    }

    return http.post(`/v2/signin/email?email=${email}&password=${password}`, params, headers);
  }

  getPlace(){
    return http.get('/v2/places');
  }

  getSpace(placeId){
    return http.get(`/v2/places/${placeId}/spaces`);
  }

  getSpaceEnvir(placeId, spaceId){
    return http.get(`/v2/places/${placeId}/spaces/${spaceId}`);
  }

  getDevices(placeId, spaceId){
    return http.get(`/v2/places/${placeId}/spaces/${spaceId}/devices`);
  }

  getDevicesDetail(placeId, spaceId, deviceID){
    return http.get(`/v2/places/${placeId}/spaces/${spaceId}/devices/${deviceID}`);
  }

  getAdminData(){
    return http.get('/v2/admin/spaces/env-status/')
  }

  getTrend(placeId, spaceId){
    let endHour = new Date();
    let year = endHour.getFullYear(); //yyyy
    let month = 1 + endHour.getMonth(); //M
    month = month >= 10 ? month : "0" + month; //month 두자리로 저장
    let day = endHour.getDate(); //d
    day = day >= 10 ? day : "0" + day; //day 두자리로 저장
    let hour = endHour.getHours();
    hour = hour >= 10 ? hour : "0" + hour;
    endHour =  year + "" + month + "" + day + "" + hour;

    return http.get(`/v2/places/${placeId}/spaces/${spaceId}/trends?endDate=${endHour}&startDate=2020010100&trendType=HOURLY`)
  }
}

export default new API();