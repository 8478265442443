import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userState: {
      accessToken: '',
      refreshToken: '',
      userId: '000',
    },
    userPlace: [{
      id: 0,
      placeId: 0,
      placeName: "",
    }],
    userSpace: [{
      adminId: 0,
      lastSyncModDt: "",
      modDt: "",
      placeId: 0,
      regDt: "",
      spaceId: 0,
      spaceName: "",
      spaceType: "",
    }],
    userSpaceEnv: [{

    }],
    userTrend: [{

    }],
    userDevices: [{}],
    userDevciesDetail: [{}],
    isLoading: false,
    autoPlayState: true,
    dataList: [],
    isSpaceSelect: '전체',
    dataForm: {
      spaceId: 0,
      spaceName: '',
      ctrlFan: 0,
      ctrlMode: 0,
      ctrlOnOff: 0,
      ctrlOperationMode: 0,
      ctrlTemp: 0,
      ctrlTempName: "",
      envCo2: 0,
      envHumid: 0,
      envPm10: 0,
      envPm25: 0,
      envTemp: 0,
      envTvoc: 0,
      gsConnStatus: 0,
      schActive: 0,
      msBattLevel: 0,
      msConnStatus: 0,
      placeId: 0,
      placeName: "",
      scBattLevel: 0,
      scConnStatus: 0,
    },
    trendForm: {
      baseDate: "",
      co2: 0,
      humid: 0,
      pm01: 0,
      pm10: 0,
      pm25: 0,
      temp: 0,
      voc: 0,
    }
  },
  getters: {
    getuserDevciesDetail: state => {
      return state.userDevciesDetail;
    },
    getuserDevices: state => {
      return state.userDevices
    },
    getuserTrend: state => {
      return state.userTrend;
    },
    getuserSpaceEnv: state => {
      return state.userSpaceEnv;
    },
    getUserSpace: state => {
      return state.userSpace;
    },

    getUserPlace: state => {
      return state.userPlace
    },
    getUserState: state => {
      return state.userState
    },
    getLoading: state => {
      return state.isLoading;
    },
    getDataList: state => {
      return state.dataList;
    },
    getAutoPlayState: state => {
      return state.autoPlayState
    },
    getSpaceSelect: state => {
      return state.isSpaceSelect
    }
  },
  mutations: {
    setDataReset: (state, data) => {
      console.log(data);
      let stateKey = Object.keys(state);

      for (let i = 0; i < stateKey.length; i++) {

        let stateType = typeof state[stateKey[i]];
        // console.log(stateType);

        if (stateType === 'object') {
          state[stateKey[i]] = {}
        } else if (stateType === 'Array') {
          state[stateKey[i]] = []
        } else if (stateType === 'boolean') {
          let value = state[stateKey[i]];
          state[stateKey[i]] = value
        } else if (stateType === 'string') {
          state[stateKey[i]] == ''
        } else {
          state[stateKey[i]] == null

        }
      }
    },
    setuserDevciesDetail: (state, data) => {
      state.userDevciesDetail = data;
    },
    setuserDevices: (state, data) => {
      state.userDevices = data;
    },
    setuserTrend: (state, data) => {
      state.userTrend = data;
    },
    setuserSpaceEnv: (state, data) => {
      state.userSpaceEnv = data;
    },
    setUserSpace: (state, data) => {
      state.userSpace = data;
    },
    setUserPlace: (state, data) => {
      state.userPlace = data;
    },
    setUserState: (state, data) => {
      state.userState = data;
    },
    setLoading: (state, data) => {
      state.isLoading = data;
    },
    setSpaceSelect: (state, data) => {
      state.isSpaceSelect = data;
    },
    setAutoPlayState: (state, data) => {
      state.autoPlayState = data;
    },
    setDataList: (state, data) => {
      state.dataList = data;
      // for (let i = 0; i < data.length; i++) {
      //   let ignoreTrend = data[i].trends;

      // let result = await this.checkDataForm(state, data[i]);

      //   if (result === 'failed') {
      //     console.log('setDataList Failed');
      //     return 'failed'
      //   } else {

      //     result = await actions.checkTrendForm(state, ignoreTrend);

      //     if (result === 'failed') {
      //       console.log('setDataList Failed');
      //       return 'failed'
      //     } else {
      //       continue;
      //     }
      //   }
      // }

      // state.dataList = data;
    }
  },
  actions: {
    checkData: async (context, data) => {
      for (let i = 0; i < data.length; i++) {
        let ignoreTrend = data[i].trends;

        let result = await context.dispatch('checkDataForm', data[i]);
        if (result === false) {
          console.log('setDataList Failed');
          return false
        } else {

          for (let j = 0; j < ignoreTrend.length; j++) {
            result = await context.dispatch('checkTrendForm', ignoreTrend[j]);
            if (result === false) {
              console.log('setDataList Failed');
              return false;
            } else {
              continue;
            }
          }

        }
      }

      context.commit('setDataList', data);



    },

    checkDataForm: (context, data) => {

      let formKey = Object.keys(context.state.dataForm);
      let dataKey = Object.keys(data);

      for (let i = 0; i < formKey.length; i++) {
        if (dataKey.includes(formKey[i]) === false) {
          console.log('Non Detect Data Key');
          return false;
        }
      }

      return true;
    },
    checkTrendForm: (context, data) => {

      let formKey = Object.keys(context.state.trendForm);
      let dataKey = Object.keys(data);

      for (let i = 0; i < formKey.length; i++) {
        if (dataKey.includes(formKey[i]) === false) {
          console.log('Non Detect Trend Key');
          return false;
        }
      }
      return true;
    }
  },
  modules: {},
});
