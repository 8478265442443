
class GradientCondition {

  makeFunction(code) {
    let data = Function('"use strict";' + code)()
    return data;
  }

  tempCheckValues(valueSet, colorSet, inValue) {
    let functionString = ''
    for (let i = 0; i < valueSet.length - 1; i++) {
      if (i === 0) {
        functionString += `let value = ${inValue}; if(value >= ${valueSet[i]} && value < ${valueSet[i + 1]}){return '${colorSet[i]}'}`
      } else {
        functionString += `else if(value >= ${valueSet[i]} && value < ${valueSet[i + 1]}){return '${colorSet[i]}'}`
      }
    }

    functionString += `else { return 'B' }`

    let result = this.makeFunction(functionString);

    return result;
  }

  good = "rgba(28, 176, 255, 0.5)";
  normal = "rgba(104, 201, 149, 0.5)";
  warning = "rgba(255, 178, 74, 0.5)";
  bad = "rgba(251, 106, 87, 0.5)";

  gradiG(start, graph) {
    if (isNaN(start) === false) {
      graph.addColorStop(start, this.good);

    }
  }

  gradiN(start, graph) {
    if (isNaN(start) === false) {

      graph.addColorStop(start, this.normal);
    }
  }

  gradiW(start, graph) {
    if (isNaN(start) === false) {

      graph.addColorStop(start, this.warning);
    }
  }

  gradiB(start, graph) {
    if (isNaN(start) === false) {

      graph.addColorStop(start, this.bad);
    }
  }


  // GtoG(start, end, graph) {
  //   graph.addColorStop(start, this.good);
  //   graph.addColorStop(end, this.good);
  // }
  // GtoN(start, end, graph) {
  //   graph.addColorStop(start, this.good);
  //   graph.addColorStop(end, this.normal);
  // }
  // GtoW(start, end, graph) {
  //   graph.addColorStop(start, this.good);
  //   graph.addColorStop(end, this.warning);
  // }
  // GtoB(start, end, graph) {
  //   graph.addColorStop(start, this.good);
  //   graph.addColorStop(end, this.bad);
  // }
  // NtoG(start, end, graph) {
  //   graph.addColorStop(start, this.normal);
  //   graph.addColorStop(end, this.good);
  // }
  // NtoN(start, end, graph) {

  //   graph.addColorStop(start, this.normal);
  //   graph.addColorStop(end, this.normal);
  // }
  // NtoW(start, end, graph) {
  //   graph.addColorStop(start, this.normal);
  //   graph.addColorStop(end, this.warning);
  // }
  // NtoB(start, end, graph) {
  //   graph.addColorStop(start, this.normal);
  //   graph.addColorStop(end, this.bad);
  // }
  // WtoG(start, end, graph) {
  //   graph.addColorStop(start, this.warning);
  //   graph.addColorStop(end, this.good);
  // }
  // WtoN(start, end, graph) {
  //   graph.addColorStop(start, this.warning);
  //   graph.addColorStop(end, this.normal);
  // }
  // WtoW(start, end, graph) {
  //   graph.addColorStop(start, this.warning);
  //   graph.addColorStop(end, this.warning);
  // }
  // WtoB(start, end, graph) {
  //   graph.addColorStop(start, this.warning);
  //   graph.addColorStop(end, this.bad);
  // }
  // BtoG(start, end, graph) {
  //   graph.addColorStop(start, this.bad);
  //   graph.addColorStop(end, this.good);
  // }
  // BtoN(start, end, graph) {
  //   graph.addColorStop(start, this.bad);
  //   graph.addColorStop(end, this.normal);
  // }
  // BtoW(start, end, graph) {
  //   graph.addColorStop(start, this.bad);
  //   graph.addColorStop(end, this.warning);
  // }
  // BtoB(start, end, graph) {
  //   graph.addColorStop(start, this.bad);
  //   graph.addColorStop(end, this.bad);
  // }
}

export default GradientCondition;