<template>
  <div class="TopbarComponents">
    <b-navbar
      class="TopbarNav"
      toggleable="sm"
      type="light"
      variant="light"
      :sticky="true"
    >
      <b-navbar-toggle target="nav-text-collapse"></b-navbar-toggle>

      <b-navbar-brand
        :style="{ fontSize: '25px', display: 'flex', alignItems: 'center' }"
      >
        <img :src="brandIcon" alt="logo" width="30" height="30" />
        <p :style="{ margin: '0', width: '150px' }">KIE-MECS</p>
      </b-navbar-brand>

      <b-collapse id="nav-text-collapse" is-nav>
        <b-navbar-nav>
          <div v-if="selectSpace === '전체'">
            <b-icon-play-fill
              v-if="autoPlayState === false"
              font-scale="2"
              @click="setAutoPlayButton(true)"
              :style="{ cursor: 'pointer' }"
            ></b-icon-play-fill>
            <b-icon-pause-fill
              v-else
              font-scale="2"
              @click="setAutoPlayButton(false)"
              :style="{ cursor: 'pointer' }"
            ></b-icon-pause-fill>
          </div>
        </b-navbar-nav>
        <b-navbar-nav align="right">
          <b-nav-form>
            <b-form-select
              class="selectSpaceForm"
              v-model="selectSpace"
              :options="selectOptions"
              @change="setSpace"
            >
              <!-- <b-form-select-option :value="`전체`"
                ><span>전체</span>
              </b-form-select-option>
      
                <b-form-select-option
                  v-for="(data, i) in spaceData"
                  :key="`spaceKey${i}`"
                  :value="data.spaceName"
                  >{{ data.spaceName }}
                </b-form-select-option>
           -->
            </b-form-select>
          </b-nav-form>
        </b-navbar-nav>
        <b-navbar-nav>
          <b-nav-item-dropdown
            text="Login"
            right
            class="m-2"
            :style="{ width: 'auto' }"
          >
            <template #button-content>
              <b-icon-person-circle font-scale="2"></b-icon-person-circle>
            </template>
            <b-dropdown-item @click="viewAlert">상세정보</b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item @click="viewAlert">로그아웃</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
        <!-- <b-navbar-nav>
          <b-col class="mb-2">
            <b-icon icon="google"></b-icon>
          </b-col>
        </b-navbar-nav> -->
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TopbarComponents",
  data() {
    return {
      brandIcon: require("../../assets/icon/ico_app.png"),
      selectSpace: "전체",
    };
  },
  computed: {
    ...mapGetters({
      spaceData: "getDataList",
      autoPlayState: "getAutoPlayState",
    }),
    selectOptions() {
      let options = [{ value: "전체", text: "전체" }];
      let importForm = [];

      for (let i = 0; i < this.spaceData.length; i++) {
        let isPlace = false;
        for (let j = 0; j < importForm.length; j++) {
          if (importForm[j].label === this.spaceData[i].placeName) {
            isPlace = true;

            importForm[j].options.push({
              value: this.spaceData[i].spaceName,
              text: this.spaceData[i].spaceName,
            });
            break;
          }
        }

        if (isPlace === false) {
          importForm.push({
            label: this.spaceData[i].placeName,
            options: [
              {
                value: this.spaceData[i].spaceName,
                text: this.spaceData[i].spaceName,
              },
            ],
          });
        }
      }

      // console.log(importForm);

      for (let i = 0; i < importForm.length; i++) {
        importForm[i].options.sort((a, b) => {
          let aText = a.text.split(".");
          let bText = b.text.split(".");

          if (parseInt(aText[0]) > parseInt(bText[0])) {
            return 1;
          }

          if (parseInt(aText[0]) === parseInt(bText[0])) {
            return 0;
          }

          if (parseInt(aText[0]) < parseInt(bText[0])) {
            return -1;
          }

          if(aText.length === 1){
            return 1
          }

          if(bText.length === 1){
            return -1
          }

          // return -1;
        });

        options.push(importForm[i]);
      }

      // let importForm = {
      //   label: "KBeT 7층",
      //   options: [],
      // };

      // for (let i = 0; i < this.spaceData.length; i++) {
      //   if (this.spaceData[i].placeName === "KBeT 7층") {
      //     importForm.options.push({
      //       value: this.spaceData[i].spaceName,
      //       text: this.spaceData[i].spaceName,
      //     });
      //   }
      // }

      // options.push(importForm);

      // importForm = {
      //   label: "KBeT 8층",
      //   options: [],
      // };

      // for (let i = 0; i < this.spaceData.length; i++) {
      //   if (this.spaceData[i].placeName === "KBeT 8층") {
      //     importForm.options.push({
      //       value: this.spaceData[i].spaceName,
      //       text: this.spaceData[i].spaceName,
      //     });
      //   }
      // }

      // options.push(importForm);

      // for (let i = 0; i < this.spaceData.length; i++) {
      //   if (
      //     this.spaceData[i].placeName !== "KBeT 7층" &&
      //     this.spaceData[i].placeName !== "KBeT 8층"
      //   ) {
      //     options.push({
      //       value: this.spaceData[i].spaceName,
      //       text: this.spaceData[i].spaceName,
      //     });
      //   }
      // }

      return options;
    },
  },
  methods: {
    setSpace(space) {
      // this.$store.commit('setLoading', true);

      this.selectSpace = space;
      // console.log(space);
      this.$store.commit("setSpaceSelect", space);
    },
    setAutoPlayButton(value) {
      this.$store.commit("setAutoPlayState", value);
    },
    viewAlert() {
      alert("준비중입니다.");
    },
    logout(){
      this.$store.commit('setDataReset', '')
      this.$router.go(-1);
    }
  },
};
</script>

<style lang="scss">
.TopbarComponents {
  width: 100%;
  background-color: #282743;

  .TopbarNav {
    height: 65px;
    @media screen and (max-width: 576px) {
      padding-left: 5px;
      padding-right: 5px;
      height: auto;
    }
    #nav-text-collapse {
      padding: 10px;
      justify-content: end;

      .selectSpaceForm {
        border-radius: 5px;
        padding: 5px;
        margin: 10px;
      }

      .dropdown-menu-right {
        right: 0;
      }
    }
  }
}
</style>